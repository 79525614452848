import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ _description, _title, _ogImage }) => {
    const { wp } = useStaticQuery(
        graphql`
            query {
                wp {
                    generalSettings {
                        title
                    }
                    themeOptions {
                        common {
                            commonOgImage {
                                localFile {
                                    publicURL
                                }
                            }
                            commonOgDescription
                        }
                    }
                }
            }
        `
    )

    const metaDescription: string = _description || wp.themeOptions?.common.commonOgDescription
    const title: string =
        _title === '' ? wp.generalSettings?.title : `${_title} - ${wp.generalSettings?.title}`
    const ogImage =
        _ogImage !== null
            ? _ogImage.localFile.publicURL
            : wp.themeOptions.common.commonOgImage.localFile.publicURL

    return (
        <>
            <title>{title}</title>
            <meta name="format-detection" content="telephone=no" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content={metaDescription} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:locale" content="ja" />
            <meta name="og:image" content={process.env.GATSBY_WEB_URL + ogImage} />
            <meta name="og:url" content={process.env.GATSBY_WEB_URL} />
            <meta name="og:site_name" content={title} />
            <meta name="og:type" content={_title === '' ? 'website' : 'article'} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            <link id="favicon" rel="icon" href="/favicon/00.ico" />
        </>
    )
}

export default Seo

Seo.defaultProps = {
    _description: null,
    _title: null,
    _ogImage: null,
}

Seo.propTypes = {
    _description: PropTypes.string,
    _title: PropTypes.string,
    _ogImage: PropTypes.shape({
        localFile: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }),
    }),
}
