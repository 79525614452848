import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import ProfileText from '../../ui/profileText'
import Image from '../../utils/image'
import Hover from '../../utils/hover'

import DownloadIcon from '../../../images/download-icon.svg'
import DownloadIconSP from '../../../images/download-icon_sp.svg'

const AboutPageComponent = ({ _dataAbout }) => {
    return (
        <div className={styles.about}>
            <div className={styles.about__profile_wrap}>
                <section className={styles.about__profile}>
                    <ProfileText _roll={_dataAbout.roll} _description={_dataAbout.profile} />
                </section>
                <section className={styles.about__profile_jp}>
                    <ProfileText
                        _roll={_dataAbout.rollJp}
                        _description={_dataAbout.profileJp}
                        _isJp
                    />
                </section>
            </div>
            {_dataAbout.profileImage && _dataAbout.profileImageDlLink && (
                <div className={styles.about__profile_image}>
                    <Image _data={_dataAbout.profileImage.localFile} _alt="Profile Image" />
                    {_dataAbout.profileImageDlLink && (
                        <div className={styles.about__download}>
                            <Hover hoverClass={styles.about__download_link_hovered}>
                                <a
                                    href={_dataAbout.profileImageDlLink}
                                    className={styles.about__download_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span className={styles.about__download_icon}>
                                        <picture>
                                            <source
                                                srcSet={DownloadIconSP}
                                                media="(max-width: 900px)"
                                            />
                                            <img src={DownloadIcon} alt="download icon" />
                                        </picture>
                                    </span>
                                    <span className={styles.about__download_text}>Portrait</span>
                                </a>
                            </Hover>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AboutPageComponent

AboutPageComponent.propTypes = {
    _dataAbout: PropTypes.shape({
        roll: PropTypes.string.isRequired,
        profile: PropTypes.string.isRequired,
        rollJp: PropTypes.string.isRequired,
        profileJp: PropTypes.string.isRequired,
        profileImage: PropTypes.shape({
            localFile: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    gatsbyImageData: PropTypes.oneOfType([
                        PropTypes.shape({}),
                        PropTypes.arrayOf(PropTypes.shape({})),
                    ]),
                }),
            }),
        }),
        profileImageDlLink: PropTypes.string,
    }).isRequired,
}
