import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Seo from '../components/utils/seo'

import AboutPageComponent from '../components/page/aboutPageComponent'

const AboutPage: React.FC<PageProps<GatsbyTypes.AboutPageQuery>> = ({ data }) => {
    return <AboutPageComponent _dataAbout={data.allWpPage.edges[0].node.about} />
}

export default AboutPage

export const Head = () => <Seo _title="About" />

export const pageQuery = graphql`
    query AboutPage {
        allWpPage(filter: { slug: { eq: "about" } }) {
            edges {
                node {
                    about {
                        roll
                        profile
                        rollJp
                        profileJp
                        profileImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(formats: [AUTO, WEBP], width: 528)
                                }
                            }
                        }
                        profileImageDlLink
                    }
                }
            }
        }
    }
`
