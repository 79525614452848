import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './style.module.sass'

const Image = ({ _data, _alt }) => {
    const image = getImage(_data)

    return <GatsbyImage image={image} alt={_alt} className={styles.image} />
}

Image.propTypes = {
    _data: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.oneOfType([
                PropTypes.shape({}),
                PropTypes.arrayOf(PropTypes.shape({})),
            ]),
        }),
    }).isRequired,
    _alt: PropTypes.string.isRequired,
}

export default Image
