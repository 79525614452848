import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

const ProfileText = ({ _roll, _description, _isJp }) => {
    return (
        <>
            {_isJp ? (
                <h2 className={`${styles.profileText__name} ${styles.profileText__name_jp}`}>
                    井口 皓太
                </h2>
            ) : (
                <h1 className={`${styles.profileText__name}`}>Kota Iguchi</h1>
            )}
            <h3
                className={
                    _isJp
                        ? `${styles.profileText__roll} ${styles.profileText__roll_jp}`
                        : styles.profileText__roll
                }
                dangerouslySetInnerHTML={{
                    __html: _roll,
                }}
            />
            <p
                className={
                    _isJp
                        ? `${styles.profileText__description} ${styles.profileText__description_jp}`
                        : styles.profileText__description
                }
                dangerouslySetInnerHTML={{
                    __html: _description,
                }}
            />
        </>
    )
}

export default ProfileText

ProfileText.defaultProps = {
    _isJp: false,
}

ProfileText.propTypes = {
    _roll: PropTypes.string.isRequired,
    _description: PropTypes.string.isRequired,
    _isJp: PropTypes.bool,
}
