// extracted by mini-css-extract-plugin
export var about = "style-module--about--9c979";
export var about__download = "style-module--about__download--d2d9f";
export var about__download_icon = "style-module--about__download_icon--55c1f";
export var about__download_link = "style-module--about__download_link--58971";
export var about__download_link_hovered = "style-module--about__download_link_hovered--af540";
export var about__download_text = "style-module--about__download_text--1e21e";
export var about__profile = "style-module--about__profile--7dc44";
export var about__profile_image = "style-module--about__profile_image--78a72";
export var about__profile_jp = "style-module--about__profile_jp--8ab3e";
export var about__profile_wrap = "style-module--about__profile_wrap--0303c";
export var bowAnimation = "style-module--bowAnimation--b3f46";